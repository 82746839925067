<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Brand Tax</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">Brand Name</span>
        <v-select
          label="name"
          :options="brandOptions"
          :reduce="brand => brand.id"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="payload.hotelId"
        />
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <vs-input
          type="number"
          class="w-full"
          label="Tax (*)"
          v-model="payload.tax"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("tax")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <vs-input
          type="number"
          class="w-full"
          label="Service Fee (*)"
          v-model="payload.fee"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("service_fee")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Store Module
import moduleBrandTaxManagement from "@/store/brand-tax-management/moduleBrandTaxManagement.js";

export default {
  name: "BrandTaxAdd",

  metaInfo: {
    title: "Add Brand Tax",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect
  },

  data: () => ({
    payload: {
      hotelId: "",
      tax: 0,
      fee: 0
    },
    brandOptions: []
  }),

  methods: {
    getBrands() {
      axios
        .get("hotels")
        .then(({ data: res }) => {
          this.brandOptions = res.data.hotels
            .map(brand => {
              return {
                id: brand.id,
                name: brand.name
              };
            });
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    storeData() {
      const payload = {
        ...this.payload,
        hotelId: parseInt(this.payload.hotelId),
        fee: parseInt(this.payload.fee),
        tax: parseInt(this.payload.tax),
      }
      this.$store
        .dispatch("brandTaxManagement/storeBrandTax", payload)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: 'Brand Tax has been added',
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/brand-taxes");
        })
        .catch(err => {
          this.$catchErrorResponse(err);
        });
    }
  },

  mounted() {
  },

  created() {
    if (!moduleBrandTaxManagement.isRegistered) {
      this.$store.registerModule(
        "brandTaxManagement",
        moduleBrandTaxManagement
      );
      moduleBrandTaxManagement.isRegistered = true;
    }

    this.getBrands();
  }
};
</script>

<style></style>
